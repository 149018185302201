<template>
  <v-card-text class="pa-2">
    <v-row style="overflow-x: auto; overflow-y: hidden;">
      <v-col class="mb-4">
        <!-- <span class="py-2 text-h6">{{ $t('condAdmin.additionalProduct.tariffLines') }}</span> -->
        <table class="text-center additionalProductTable v-data-table" cellspacing=0 cellpadding=0><!--
          <tr>
            <td class="border"></td>
            <th
              class="pa-2 firstRow"
              v-for="(td) in params.tariffDescription"
              :key="td.index"
            >
            </th>
          </tr> -->
          <tr>
            <td class="text-center firstRow first px-2">{{ $t('condAdmin.additionalProduct.tariffSubName') }}</td>
            <td class="px-4 firstRow" v-for="(td, index) in params.tariffDescription" :key="index">
              <span v-if="readonly == true">{{ td.tariffName }}</span>
              <EditField v-if="readonly !== true" @change="setTariffName(index, $event)" :value="td.tariffName" clearable
                :readonly="readonly" />
            </td>
          </tr>
          <tr>
            <td class="text-center first px-2">{{ $t('condAdmin.additionalProduct.tariffId') }}</td>
            <td class="px-4" v-for="(td, index) in params.tariffDescription" :key="index">
              <span v-if="readonly == true"><strong>{{ td.tariffId }}</strong></span>

              <EditField v-if="readonly !== true" @change="setTariffId(index, $event)" :value="td.tariffId" clearable
                :readonly="readonly" type="int" />

            </td>
          </tr>
        </table>
      </v-col>
    </v-row>
    <!--
    <v-row v-if="tmpParams != null">
      <v-col class="pb-0 text-h6">
        <span>{{ $t('condAdmin.condition.additionalData') }}</span>
      </v-col>
    </v-row>
    -->
    <v-row v-if="tmpParams != null">
      <v-col cols="12" lg="4">
        <v-select v-if="readonly !== true" v-model="tmpParams.tariffValues.paymentMode" :items="paymentModes"
          item-text="text" item-value="value"
          :label="$t('base.paymentMode.label') + $filters.inBrackets(texts.de.contribution)"
          @change="$emit('setDirty')"></v-select>
        <EditField v-model="tmpParams.tariffValues.value"
          :label="texts.de.contribution + (readonly ? $filters.inBrackets($t('base.paymentMode.' + tmpParams.tariffValues.paymentMode)) : '')"
          @change="$emit('setDirty')" hideDetails :rules="[rules.required]" :readonly="readonly" :suffix="currency"
          type="ufloat" />
      </v-col>
      <v-col cols="12" lg="4">
        <v-select v-if="readonly !== true" v-model="tmpParams.tariffCommission.paymentMode" :items="paymentModes"
          item-text="text" item-value="value"
          :label="$t('base.paymentMode.label') + $filters.inBrackets(texts.de.commission)"
          @change="$emit('setDirty')"></v-select>
        <EditField v-model="tmpParams.tariffCommission.value"
          :label="texts.de.commission + (readonly ? $filters.inBrackets($t('base.paymentMode.' + tmpParams.tariffCommission.paymentMode)) : '')"
          @change="$emit('setDirty')" hideDetails :readonly="readonly" :suffix="currency" type="ufloat" />
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script lang="js">
import condAdminAdditionalProductMixin from '@/condAdmin/js/condAdminAdditionalProductMixin.js';
import EditField from '@/common/EditField';

export default {
  name: 'AdditionalProduct_fix_yareto',
  components: {
    EditField
  },
  mixins: [condAdminAdditionalProductMixin],
  props: {
    params: { type: Object, required: true },
    readonly: { type: Boolean, default: false }
  },
  data () {
    return {
      tmpParams: null,
      texts: {
        de: {
          contribution: 'Beitrag',
          commission: 'Provision'
        }
      }
    };
  },
  computed: {},
  watch: {
    params () {
      this.tmpParams = this.params;
    }
  },
  mounted () {
    this.tmpParams = this.params;
  },
  methods: {
    setTariffName (index, value) {
      if (!this.tmpParams.isDirty) {
        this.tmpParams.isDirty = true;
      }
      this.tmpParams.tariffDescription[index].tariffName = value;
    },
    setTariffId (index, value) {
      if (!this.tmpParams.isDirty) {
        this.tmpParams.isDirty = true;
      }
      this.tmpParams.tariffDescription[index].tariffId = value;
    }
  }
};
</script>

<style scoped>
.additionalProductTable>>>.v-input__append-outer .v-icon {
  color: var(--v-secondary-base) !important;
}

.additionalProductTable td,
.additionalProductTable th,
.greyRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
}

.additionalProductTable .borderBottom {
  border-right: 0px !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
}

.additionalProductTable td.noBorder {
  border: 0px !important;
}

.additionalProductTable td.first,
.additionalProductTable th.first,
.greyRow.first {
  border-left: 1px solid var(--v-grey-lighten3) !important;
}

.firstRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-top: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
  min-width: 120px !important;
}

.greyRow {
  border-right: 1px solid var(--v-grey-lighten3) !important;
  border-bottom: 1px solid var(--v-grey-lighten3) !important;
  background-color: var(--v-grey-lighten4) !important;
  color: var(--v-grey-darken1) !important;
}

.subsidyField>>>.v-text-field__slot,
.subsidyField>>>input {
  color: var(--v-error-base) !important;
}
</style>
